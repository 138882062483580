import React from "react"
import styled from "styled-components"
import Layout from "../shared/Layout/layout"
import SEO from "../components/seo"
import { ImageBlock } from "../shared/Block/block"
import { ButtonLight } from "../shared/Button/button"

/*const ColoredSpan = ({ bg_color, children }) => (
  <div
    style={{
      display: `flex`,
    }}
  >
    <div
      style={{
        display: `flex`,
        justifyItems: `center`,
        // display: `inline-flex`,
        // padding: `2rem`,
        // height: `1rem`,
        backgroundColor: `${bg_color}`,
        // backgroundColor: `var(--color-shock-1)`,
      }}
    >
      {children}
    </div>
  </div>
)*/

const PriceTagDiv = styled.div`
  display: inline-flex;
  font-family: josefin sans;
`

const PriceTag = ({ children, price_number = "" }) => {
  return (
    <>
      <PriceTagDiv
        style={{
          fontSize: `2.8rem`,
          fontWeight: `bold`,
          color: `var(--color-shock-5)`,
        }}
      >
        {price_number}
      </PriceTagDiv>{" "}
      <PriceTagDiv
        style={{
          color: `var(--color-shock-5)`,
        }}
      >
        {children}
      </PriceTagDiv>
    </>
  )
}

const Span = styled.div`
  text-shadow: 0px 0px 4px ${props => props.shadow || "rgb(0, 0, 0, 0.5)"};
  font-family: nunito sans;
  color: var(--color-primary-10);
  padding: 1rem 0 0 1rem;
`

const LabelSpan = styled(Span)`
  text-align: right;
  padding: 1rem 1rem 0 0;
`

const SmallPrint = styled(Span)`
  font-size: 0.75rem;

  &:last-of-type {
    margin-bottom: 3rem;
  }
`

const TechnicalSpan = styled(Span)`
  background-color: rgb(90, 90, 90, 0.5);
`

const PackageSpan = styled(Span)`
  background-color: rgb(90, 90, 90, 0.5);
`

const PricingPage = () => {
  return (
    <>
      <Layout>
        <SEO title="Hinnasto" />

        <ImageBlock
          background_gradient="
            no-repeat radial-gradient(
              ellipse at center,
              var(--color-primary-05),
              var(--color-primary-02)
            )
          "
          flex_dir="flex-end"
          preferred_max_width="74rem"
          min_height="70rem"
          min_mobile_height="70rem"
        >
          <h1 style={{ marginBottom: `3rem` }}>Hinnasto</h1>

          <div
            style={{
              marginBottom: `2rem`,
              display: `grid`,
              gridTemplateColumns: `repeat(3, minmax(130px, 1fr))`,
              // gridGap: `2rem`,
              columnGap: `1rem`,
              gridAutoRows: `6rem`,
            }}
          >
            <Span></Span>
            <TechnicalSpan
              style={{
                color: `var(--color-primary-13)`,
                display: `inline-flex`,
                textShadow: `0 0 0 transparent`,
              }}
            >
              <h3>Tekninen apu</h3>
            </TechnicalSpan>

            <PackageSpan
              style={{
                color: `var(--color-primary-13)`,
                textShadow: `0 0 0 transparent`,
              }}
            >
              <h3 style={{ display: `inline-block` }}>Valmis paketti</h3>
              <h3 style={{ display: `inline-block`, fontWeight: `normal` }}>
                *
              </h3>
            </PackageSpan>

            <LabelSpan>Työmääräarvio</LabelSpan>
            <TechnicalSpan
              className=""
              title="htp: henkilötyöpäivä, eli 7,5 tuntia"
            >
              0,5 – 5 htp**
            </TechnicalSpan>
            <PackageSpan title="htp: henkilötyöpäivä, eli 7,5 tuntia">
              5 – 15 htp** julkaisuun
            </PackageSpan>

            <LabelSpan>Sivujen valmistumisen aikataulu</LabelSpan>
            <TechnicalSpan className="">
              Asiakkaan omassa tahdissa
            </TechnicalSpan>
            <PackageSpan>Yhdessä sovitussa aikataulussa</PackageSpan>

            <LabelSpan>Tavoitettavissa</LabelSpan>
            <TechnicalSpan className="">
              Päivittäin muutaman tunnin kuluessa
            </TechnicalSpan>
            <PackageSpan>Päivittäin muutaman tunnin kuluessa</PackageSpan>

            <LabelSpan>Hinta</LabelSpan>
            <TechnicalSpan className="">
              <PriceTag price_number="30">€/h***</PriceTag>
            </TechnicalSpan>
            <PackageSpan>
              <PriceTag price_number="70">€/kk***</PriceTag>
            </PackageSpan>
          </div>

          <SmallPrint>
            * Valmiiseen pakettiin kuuluu yhdessä sovittavat sivut, teema,
            asiakkaan valitsema sisältö ja tarpeitten mukaan max. kaksi
            lomaketta. Ylläpitoa viisi htp:tä vuodessa sivuston muutoksiin
            ja korjailuihin.
          </SmallPrint>
          <SmallPrint>** htp: henkilötyöpäivä, eli 7,5 tuntia</SmallPrint>
          <SmallPrint>*** alkaen + alv</SmallPrint>

          <p>
            Voimme myös sopia tarpeittesi mukaisesti räätälöidystä
            hybridiratkaisusta näiden väliltä. Ota yhteyttä – etsitään sopiva
            ratkaisu!
          </p>

          <p>
            <ButtonLight to="/contact" key="Contact">
              Ota yhteyttä
            </ButtonLight>
          </p>
        </ImageBlock>
      </Layout>
    </>
  )
}

export default PricingPage
