import PropTypes from "prop-types"
import styled from "styled-components"

const ReactToWidthAuto = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: ${props => (props.reverse ? "wrap-reverse" : "wrap")};
`

const ReactToWidth = styled.div`
  display: flex;
  // min-width: ${props => props.min_width};
  flex-direction: ${props => props.flex_direction || "row"};
  align-items: ${props => props.align_items || "flex-start"};

  @media (max-width: ${props => props.max_width || "40rem"}) {
    flex-direction: ${props => props.flex_direction_alternative || "column"};
    align-items: center;
    // color: red;
  }
`
ReactToWidth.propTypes = {
  // min_width: PropTypes.string.isRequired,
  flex_direction: PropTypes.string.isRequired,
  max_width: PropTypes.string.isRequired,
  flex_direction_alternative: PropTypes.string.isRequired,
}

// export default ReactToWidth
export { ReactToWidthAuto, ReactToWidth }
