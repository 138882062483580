import React from "react"
// import PropTypes from "prop-types"
import { Link } from "gatsby"

import styled from "styled-components"

const Button = styled(Link)`
  font-weight: 700;
  font-weight: normal;
  font-family: lexend deca, sans-serif;
  font-size: 1.15rem;

  text-shadow: 0 0 0 var(--color-primary-03);

  display: inline-flex;
  border-radius: 0.15rem;
  padding: 0.9rem 2.4rem;

  transition: all ease;

  transition-duration: 0.5s;
  background-color: ${props => props.background_color};
  color: ${props => props.color};
  border: solid 2px ${props => props.background_color};

  &:hover {
    transition-duration: 0.2s;
    background-color: ${props => props.background_color_hover};
    color: ${props => props.color_hover};
    border: solid 2px ${props => props.border_color_hover};

    cursor: pointer;
  }
`

const ButtonLight = props => {
  const light = "var(--color-primary-12)"
  const dark = "var(--color-primary-03)"
  const shock = "var(--color-shock-3)"
  return (
    <Button
      {...props}
      background_color={light}
      color={dark}
      background_color_hover={shock}
      color_hover={dark}
      border_color_hover={shock}
    >
      {props.children}
    </Button>
  )
}

const ButtonDark = props => {
  const light = "var(--color-primary-12)"
  const dark = "var(--color-primary-03)"
  const shock = "var(--color-shock-3)"
  return (
    <Button
      {...props}
      background_color={dark}
      color={light}
      background_color_hover={dark}
      color_hover={shock}
      border_color_hover={shock}
    >
      {props.children}
    </Button>
  )
}

const TextButton = styled(Link)`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 700;
  font-size: 1rem;

  display: inline-flex;
  padding: 0.5rem 0 0 0;
  align-self: flex-start;

  color: var(--color-primary-11);
  transition: 0.3s all ease;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid var(--color-shock-2);
    color: var(--color-shock-2);
    cursor: pointer;
  }
`

export { ButtonLight, ButtonDark, TextButton }
