import React from "react"
import styled from "styled-components"

import { ReactToWidth } from "../ReactToWidth/react_to_width"

const Div = styled.div`
  /*background: none;
  background-color: rgb(250, 0, 0, 0.15);
  & > & {
    background: none;
    background-color: rgb(0, 250, 0, 0.15);
  }
  & > & > & {
    background: none;
    background-color: rgb(0, 0, 250, 0.15);
  }
  & > & > & > & {
    background: none;
    background-color: rgb(250, 0, 0, 0.15);
  }*/
  & > *:last-child {
    margin-bottom: 0;
  }
  // var(--default-margin);
`

const Level0Wrapper = styled(Div)`
  background: ${props => props.background_gradient};
`

const Level1Wrapper = styled(Div)`
  display: flex;
  justify-content: center;
  filter: brightness(1.1) contrast(1.1);
`

const BackgroundImage = styled(Div)`
  display: flex;
  justify-content: center;

  background: no-repeat url(${props => props.image});
  background-position: ${props => props.background_position};
  background-size: ${props => props.cover_or_contain};
  // min-width: 20rem;
  position: absolute;
  z-index: -1;

  width: 100%;
  height: 100%;

  // filter: blur(1px);
`

const Level2Wrapper = styled(Div)`
  background: ${props => props.image_gradient};
  // background-color: red;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: ${props => props.preferred_max_width}) {
    background: no-repeat
      linear-gradient(90deg, rgb(0, 0, 0, 0.5) 10%, rgb(0, 0, 0, 0.2) 100%);
  }
`

const Level3Wrapper = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: ${props => props.flex_dir};

  width: ${props => props.preferred_parent_width};

  @media only screen and (max-width: calc(${props =>
      props.preferred_parent_width} + 20px)) {
    width: 90vw;
  }
`

const Level4Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: ${props => props.min_height};

  width: ${props => props.preferred_width};

  @media only screen and (max-width: ${props => props.preferred_max_width}) {
    width: 90vw;
    // min-height: calc(1.1 * ${props => props.min_height});
    min-height: ${props => props.min_mobile_height};
    // height: ${props => props.min_mobile_height};
    // height: ${props => props.min_height};
    // background-color: red;
    // color: blue;
  }
`
// @media only screen and (max-width: calc(${props => props.preferred_width} + 20px)) {

// const Level5Wrapper = styled(ReactToWidth)`
const Level5Wrapper = styled.div`
  max-width: 92vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${props => props.margin_bottom};
`

const ImageBlock = ({
  children,
  image,
  margin_bottom = "0rem",
  // text_width = "60rem",
  cover_or_contain = "contain",
  background_position = "",
  image_gradient = "",
  background_gradient = "",
  min_height = "",
  min_mobile_height = "",
  preferred_width = "900px",
  preferred_max_width = "",
  flex_dir = "flex-start",
  preferred_parent_width = "900px",
}) => {
  return (
    <Level0Wrapper background_gradient={background_gradient}>
      <Level1Wrapper>
        <BackgroundImage
          image={image}
          background_position={background_position}
          cover_or_contain={cover_or_contain}
        />
        <Level2Wrapper
          image_gradient={image_gradient}
          preferred_max_width={preferred_max_width}
        >
          <Level3Wrapper
            flex_dir={flex_dir}
            preferred_parent_width={preferred_parent_width}
          >
            <Level4Wrapper
              min_height={min_height}
              min_mobile_height={min_mobile_height}
              preferred_width={preferred_width}
              /*preferred_max_width={
                preferred_max_width ? preferred_max_width : preferred_width
              }*/
              preferred_max_width={preferred_max_width}
              // style={{ backgroundColor: `rgb(255, 0, 0, 0.3)` }}
            >
              <Level5Wrapper
                // min_width={text_width}
                // text_width={text_width}
                margin_bottom={margin_bottom}
              >
                <Div>{children}</Div>
              </Level5Wrapper>
            </Level4Wrapper>
          </Level3Wrapper>
        </Level2Wrapper>
      </Level1Wrapper>
    </Level0Wrapper>
  )
}

export { ImageBlock }
